import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://grandmodafash.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://grandmodafash.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://grandmodafash.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://grandmodafash.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://grandmodafash.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://grandmodafash.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://grandmodafash.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline1"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Наші фотопослуги
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
				color="#7c7f81"
				padding="0px 20px 0px 20px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				У студії PicturePerfect ми пропонуємо набір спеціалізованих фотопослуг, розроблених з урахуванням різноманітних потреб наших клієнтів. Незалежно від того, чи ви фіксуєте цінні моменти життя, чи розробляєте унікальну рекламну кампанію, наші послуги допоможуть втілити ваші творчі задуми в життя з точністю та чуттям.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://grandmodafash.com/images/6.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						object-fit="cover"
						sm-height="220px"
						width="100%"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Портретна фотографія
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://grandmodafash.com/images/7.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Весілля та урочистості
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://grandmodafash.com/images/8.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Корпоративні заходи
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://grandmodafash.com/images/9.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Продуктова фотографія
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://grandmodafash.com/images/10.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Фешн-фотографія
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://grandmodafash.com/images/11.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Комерційна фотографія
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0" sm-padding="40px 0 40px 0" background="url(https://grandmodafash.com/images/12.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="600px" padding="50px 50px 50px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Дізнатися більше
				</Text>
				<Text font="--base" margin="16px 0px 25px 0px">
					І це лише частина того, що ми можемо запропонувати! Завітайте до нас, щоб ознайомитися з повним спектром наших фотопослуг. Якщо вам потрібні інтимні портрети або широке висвітлення подій, студія PicturePerfect готова перевершити ваші очікування.
				</Text>
				<Button
					padding="14px 54px 14px 54px"
					background="#E65455"
					font="--lead"
					href="/contact-us"
					text-transform="uppercase"
					type="link"
					text-decoration-line="initial"
				>
					Контакти
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});